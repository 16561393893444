<h1 class="mt-4">Agregar Productos</h1>
<form (ngSubmit)="agregar()">
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label for="id">Identificador</label>
                <!-- <input [(ngModel)]="producto.id" type="text" name="identificador" class="form-control" id="id" placeholder="identificador"> -->
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="nombre">Nombre</label>
                <input [(ngModel)]="producto.nombre" name="nombre" type="text" class="form-control" id="nombre" placeholder="Nombre">
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="precio">Precio</label>
                <input [(ngModel)]="producto.costo" name="costo" type="number" class="form-control" id="costo" placeholder="Costo">
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="cantidad">Cantidad</label>
                <input [(ngModel)]="producto.cantidad" name="cantidad" type="number" class="form-control" id="cantidad" placeholder="Cantidad">
            </div>
        </div>
        <div class="col-md-12">
            <div class="form-group">
                <label for="descripcion">Descripcion</label>
                <textarea [(ngModel)]="producto.descripcion" name="descripcion" id="descripcion" class="form-control" cols="30" rows="10"></textarea>
            </div>
        </div>
        <div class="col-md-12">
            <div class="form-group">
                <button type="submit" class="btn btn-primary">Agregar</button>
            </div>
        </div>
    </div>
</form>