<footer class="footer">
    <div class="container-fluid">
        <nav class="float-left">
            <ul>
                <li>
                    <a href="https://www.creative-tim.com">Creative Tim</a>
                </li>
                <li>
                    <a href="https://creative-tim.com/presentation">About Us</a>
                </li>
                <li>
                    <a href="http://blog.creative-tim.com">Blog</a>
                </li>
                <li>
                    <a href="https://www.creative-tim.com/license">Licenses</a>
                </li>
            </ul>
        </nav>
        <div class="copyright float-right">
            &copy;
            <script>
                document.write(new Date().getFullYear());
            </script>, made with <i class="material-icons">favorite</i> by
            <a href="https://www.creative-tim.com" target="_blank">Creative Tim</a> for a better web.
        </div>
    </div>
</footer>