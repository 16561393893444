<div class="d-flex" id="wrapper">

  <!-- Sidebar -->
  <app-sidebar></app-sidebar>
  <!-- /#sidebar-wrapper -->

  <!-- Page Content -->
  <div id="page-content-wrapper">
    <app-menu></app-menu>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </div>
  <!-- /#page-content-wrapper -->

</div>