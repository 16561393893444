<div class="bg-light border-right" id="sidebar-wrapper">
    <div class="sidebar-heading">Start Bootstrap </div>
    <div class="list-group list-group-flush">
        <ul>
            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"><a routerLink="/" class="list-group-item list-group-item-action bg-light"><i class="fas fa-home"></i> Inicio</a></li>
            <li routerLinkActive="active"><a routerLink="/productos" class="list-group-item list-group-item-action bg-light"><i class="fas fa-boxes"></i> Productos</a></li>
            <li routerLinkActive="active"><a routerLink="/add-Productos" class="list-group-item list-group-item-action bg-light"><i class="fas fa-plus-circle"></i> Agregar Productos</a></li>
            <li routerLinkActive="active"><a routerLink="/finanzas" class="list-group-item list-group-item-action bg-light"><i class="fas fa-piggy-bank"></i> Finanzas</a></li>
            <li routerLinkActive="active"><a routerLink="/status" class="list-group-item list-group-item-action bg-light"><i class="fas fa-question-circle"></i> Status</a></li>
        </ul>
        
    </div>
</div>