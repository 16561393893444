<h1 class="mt-4">Productos</h1>
<div class="table-responsive">
    <table class="table table-striped">
        <thead class="thead-dark">
            <tr>
                <th scope="col">id</th>
                <th scope="col">Nombre</th>
                <th scope="col">Costo</th>
                <th scope="col">Descripcion</th>
                <th scope="col">Cantidad</th>
                <th scope="col">Acciones</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let lista of listProductos">
                <td>{{ lista.id }}</td>
                <td>{{ lista.nombre }}</td>
                <td>{{ lista.costo }}</td>
                <td>{{ lista.descripcion }}</td>
                <td>{{ lista.cantidad }}</td>
                <td>
                    <button (click)="editarProducto(lista.id)" data-toggle="modal" data-target="#exampleModal" type="button" class="actions btn btn-warning"><i class="fas fa-edit"></i></button>
                    <a type="button" class="actions btn btn-danger"><i class="fas fa-trash-alt"></i></a>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<app-edit></app-edit>